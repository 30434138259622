.gMainWrapper   { display: flex; flex-direction: column; width: 100%; height: 100%; }
.gmainHeader    { display: flex; align-items: center; height: 56px; background-color: var(--whitePrimary); border-top: 1px solid var(--brdrColor); position: fixed; top: 0; width: 100%; box-sizing: border-box; padding: 0 24px; z-index: 2;}
.gmainContainer { display: block; padding-top: 56px; height: calc(100% - 56px); position: relative; overflow-y: auto; overflow-x: hidden; width: 100%;}
.gmainFooter    { display: flex; height: 56px; background-color: #E3F0F6; align-items: center; border-top: 1px solid var(--brdrColor); position: fixed; bottom: 0; width: 100%; box-sizing: border-box; padding: 0 24px; z-index: 1; justify-content: space-between;}
.logoBox        { display: flex; align-items: center; height: 20px; width: auto;}
.logoBox img    { height: 100%; width: auto; }

.footerLinksBox                 { display: flex; align-items: center; height: 40px; }
.footerLinksBox ul              { list-style-type: none; padding: 0; margin: 0; display: flex;}
.footerLinksBox ul li           { display: flex; align-items: center; }
.footerLinksBox ul li a         { display: flex; align-items: center; justify-content: center; padding: 12px 4px; color: var(--blackLight); font-size: 12px; text-decoration: none;}
.footerLinksBox ul li a:hover   { color: var(--primaryColor); }
.ftrSmallTxt                    { color: var(--blackPrimary);}

.landingPage        { display: flex; width: 100%; min-height: 100%; flex-direction: column;}
.landingRow         { display: flex; width: 100%; box-sizing: border-box; padding: 0 12px; }
.landingCol         { display: flex; width: 50%; box-sizing: border-box; flex-direction: column; padding: 24px 12px; }
.landingBanner      { width: 100%; display: flex; align-items: center; justify-content: center; }
.landingBanner img  { width: 100%; height: 100%;}

.headingText    { font-size: 2.5rem; font-weight: 400; margin-bottom: 12px; color: var(--orangeBg); }
.quoTxt         { font-size: 1.5rem; font-weight: normal; color: var(--blackPrimary); }
.formBlock      { display: flex; padding: 40px 0px; box-sizing: border-box; flex-direction: column;}
.frmBtn         { width: 100%; height: 48px; border-radius: 50px; display: flex; align-items: center; justify-content: center; background-color: var(--primaryColor); color: var(--whitePrimary); cursor: pointer; margin-top: 24px; border: none; outline: none; font-size: 1.25rem;}

.infoTxtBlock   { display: flex; width: 100%; flex-direction: column; }
.paraText       { font-weight: normal; font-size: 1.0rem; line-height: 24px; margin-bottom: 12px; color: var(--blackPrimary);}
.paraLink       { color: var(--primaryColor); text-decoration: none;}
.extraPdng      { padding: 24px 56px;}
.smallText      { color: var(--blackLight); font-size: 0.75rem; padding: 0 16px 16px; line-height: 20px;}


@media (min-width: 768px) and (max-width: 1024px) {
    .gmainHeader    { padding: 0 16px; position: sticky; top: 0;}
    .gmainContainer { padding-top: 0; }
    .landingRow     { flex-direction: column; padding: 0; }
    .landingCol     { width: 100%; padding: 0; }
    .gmainFooter    { display: none;}
    .sftrSmallTxt   { margin-bottom: 12px;}
    .footerLinksBox { display: none;}
    .extraPdng      { padding: 24px; }
}

@media (min-width: 320px) and (max-width: 767px) {
    .gmainHeader    { padding: 0 16px; position: sticky; top: 0;}
    .gmainContainer { padding-top: 0; }
    .landingRow     { flex-direction: column; padding: 0; }
    .landingCol     { width: 100%; padding: 0; }
    .gmainFooter    { display: none;}
    .sftrSmallTxt   { margin-bottom: 12px;}
    .footerLinksBox { display: none;}
    .extraPdng      { padding: 24px; }
}