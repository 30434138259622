.sideNav {display: flex; box-sizing: border-box; border-right: 1px solid rgba(0,0,0,0.12); background-color: #ffffff;     overflow-y: auto;
    overflow-x: hidden; position: fixed; top: 64px; height: 100%;}
.sideNav > ul {display: flex; margin: 0; padding: 0; flex-direction: column; flex: 1; list-style-type: none; padding: 8px 0;}
.sideNav > ul > li {display: flex; margin: 0; padding: 0 8px; list-style-type: none;}
.sideNav > ul > li > a {display: flex; flex: 1; color: var(--blackTextColor); text-decoration: none; padding: 0 8px; border-radius: 4px; height: 48px; align-items: center; font-size: 14px; font-weight: 450;}
.sideNav > ul > li > a:hover { background-color: rgba(0, 138, 188, 0.06); color: var(--primaryDark); }
.sideNav > ul > li > a.activeTabNav { background-color:rgba(0, 138, 188, 0.12); color: var(--primaryDark); }
.sideNav > ul > li > a > .imgLink {display: flex; width: 24px; align-items: center; justify-content: center;}
.sideNav > ul > li > a > .imgLink > img {width: 24px; height: 24px; -webkit-filter: grayscale(100%); filter: grayscale(100%);}
.sideNav > ul > li > a:hover > .imgLink > img {width: 24px; height: 24px; -webkit-filter: grayscale(0%); filter: grayscale(0%);}
.sideNav > ul > li > a.activeTabNav > .imgLink > img {width: 24px; height: 24px; -webkit-filter: grayscale(0%); filter: grayscale(0%);}
.sideNav > ul > li > a > .txtLink {margin-left: 16px;}


.responsiveSideNav {display: flex; box-sizing: border-box; border-right: 1px solid rgba(0,0,0,0.12); background-color: #ffffff; overflow-y: auto;
    overflow-x: hidden; position: fixed; top: 64px; height: 100%;}
.responsiveSideNav > ul {display: flex; margin: 0; padding: 0; flex-direction: column; flex: 1; list-style-type: none; padding: 8px 0;}
.responsiveSideNav > ul > li {display: flex; margin: 0; padding: 0 8px; list-style-type: none;}
.responsiveSideNav > ul > li > a {display: flex; flex: 1; color: var(--blackTextColor); text-decoration: none; padding: 0 8px; border-radius: 4px; height: 48px; align-items: center; font-size: 14px; font-weight: 450;}
.responsiveSideNav > ul > li > a:hover { background-color: rgba(0, 138, 188, 0.06); color: var(--primaryDark); }
.responsiveSideNav > ul > li > a.activeTabNav { background-color:rgba(0, 138, 188, 0.12); color: var(--primaryDark); }
.responsiveSideNav > ul > li > a > .imgLink {display: flex; width: 24px; align-items: center; justify-content: center;}
.responsiveSideNav > ul > li > a > .imgLink > img {width: 24px; height: 24px; -webkit-filter: grayscale(100%); filter: grayscale(100%);}
.responsiveSideNav > ul > li > a:hover > .imgLink > img {width: 24px; height: 24px; -webkit-filter: grayscale(0%); filter: grayscale(0%);}
.responsiveSideNav > ul > li > a.activeTabNav > .imgLink > img {width: 24px; height: 24px; -webkit-filter: grayscale(0%); filter: grayscale(0%);}
.responsiveSideNav > ul > li > a > .txtLink {margin-left: 16px;}
.slideInSideNav {display: none;}

/* .tempNav {position: fixed; top:0; left:0; width:100%; height: 100%; overflow: hidden; pointer-events: none;}
.tempNav::before {content:''; display: block; position: absolute; top:0; left:0; width: 100%; height:100%; background-color: rgba(0,0,0,0.4); opacity: 0;} */
/************** TABLET RESPONSIVE *************/

@media (min-width: 768px) and (max-width: 1024px) {
    .responsiveSideNav {display: flex; top:0; left:0; z-index: 1;
        -ms-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -webkit-transition: all 0.3s ease; transition: all 0.3s ease; -ms-box-shadow: -1px 1px 8px -2px rgba(0,0,0,0.24);; -moz-box-shadow: -1px 1px 8px -2px rgba(0,0,0,0.24);; -webkit-box-shadow: -1px 1px 8px -2px rgba(0,0,0,0.24);; box-shadow: -1px 1px 8px -2px rgba(0,0,0,0.24); -ms-transform: translateX(-105%); -moz-transform: translateX(-105%); -webkit-transform: translateX(-105%); transform: translateX(-105%); }
    .slideInSideNav { 
        width: calc(100% - 160px);
        box-shadow: 160px 0 0 rgba(0,0,0,0.5);
        z-index: 1024;
         -ms-transform: translateX(0px); -moz-transform: translateX(0px); -webkit-transform: translateX(0px); transform: translateX(0px); }
    
}
/************** MOBILE RESPONSIVE *************/

@media (min-width: 320px) and (max-width: 767px) {
.responsiveSideNav {display: flex; top:0px; left:0; z-index: 1;
    -ms-transition: all 0.3s ease; -moz-transition: all 0.3s ease; -webkit-transition: all 0.3s ease; transition: all 0.3s ease; -ms-box-shadow: -1px 1px 8px -2px rgba(0,0,0,0.24);; -moz-box-shadow: -1px 1px 8px -2px rgba(0,0,0,0.24);; -webkit-box-shadow: -1px 1px 8px -2px rgba(0,0,0,0.24);; box-shadow: -1px 1px 8px -2px rgba(0,0,0,0.24); -ms-transform: translateX(-105%); -moz-transform: translateX(-105%); -webkit-transform: translateX(-105%); transform: translateX(-105%); }
.slideInSideNav { 
    width: calc(100% - 56px);
    box-shadow: 56px 0 0 rgba(0,0,0,0.5);
    z-index: 1024;
     -ms-transform: translateX(0px); -moz-transform: translateX(0px); -webkit-transform: translateX(0px); transform: translateX(0px); }

}