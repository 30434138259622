:root{
    --confBg: #191919;
    --blackTransparant: rgba(0,0,0,0.38);
    --whiteBg: #fff;
    --mainBg: #f1f1f1;
    --selfMsgBg: #CFEAF2;
}
.blueTxt { color: var(--blueBg);}
.yellowTxt { color: var(--yellowBg);}
.pinkTxt { color: var(--pinkBg);}
.blueTxt { color: var(--orangeBg);}
.greenTxt { color: var(--greenBg);}
.voiletTxt { color: var(--violetBg);}
.purpleTxt { color: var(--purpleBg);}
.redTxt { color: var(--endBg);}

.secondScreen {
    width: 100%;
}

.conferenceMainLayout { display: flex; position: fixed; z-index: 1000; top:0; left: 0; width: 100%; height: 100%; background-color: var(--confBg);}
.conferenceBlock { display: flex; flex-direction: column; position: relative; flex: 1; width: 100%; height: 100%; }
.screenContainer { display: flex; flex: 1 1 auto; position: relative; height: 100%; flex-direction: row; width: 100%; z-index: 1; }

/* .screenContainer:-webkit-full-screen { width: 100%; height: 100% !important;}
.screenContainer:-moz-full-screen { width: 100%; height: 100% !important;}
.screenContainer:fullscreen { width: 100%; height: 100% !important; flex: 1; } */

.confScreenContainer { display: flex; flex: 1 1 auto; position: relative; height: 100%; flex-direction: row; width: 100%; z-index: 1; }

.bottomControls { position: relative; z-index: 4; display: flex; flex: 0 0 auto; height: 80px; box-sizing: border-box; background-color: var(--blackTransparant);border-top: 1px solid rgba(255,255,255,0.12); justify-content: space-between;}
/* .itemsControl { display: inline-flex; padding: 0 24px; align-items: center; justify-content: center; position: relative; box-sizing: border-box; } */
.itemsControl { display: inline-flex; padding: 0; align-items: center; justify-content: center; position: relative; box-sizing: border-box; }
/* .rightItemsControl { display: inline-flex; padding: 0 24px; align-items: center; justify-content: center; position: relative; box-sizing: border-box; flex:0 0 auto;} */
.rightItemsControl { display: inline-flex; padding: 0; align-items: center; justify-content: center; position: relative; box-sizing: border-box; flex:0 0 auto;}


/* .langItemsControl { width: calc(50% - 128px);} */
.confControls { display: flex; position: relative;}
.confBtns { margin: 0 8px; outline: none; border: none; align-items: center; justify-content: center; display: flex; cursor: pointer; }
.confBtns img {width: 24px!important; height: 24px !important;}

.iconTxtControls { display: flex; position: relative; cursor: pointer; flex:1; justify-content:flex-start; height:80px;}
/* .iconTxtBox { display: flex; flex-direction: column; align-items:center; justify-content: center;
        border-bottom: 3px solid transparent; cursor: pointer; position: relative;
        opacity: 0.5; margin:0; padding:0 16px;
        overflow: hidden; } */
.iconTxtBox { display: flex; flex-direction: column; align-items:center; justify-content: center; border-bottom: 3px solid transparent; cursor: pointer; position: relative; opacity: 0.5; margin:0; width:  56px; overflow: hidden; }
.iconTxtBox:hover { opacity: 1; }
.iconTxtBox.active {opacity: 1; border-bottom: 3px solid var(--primaryColor);}
.iconTxtBox.active >.iconTxt {opacity: 1; color: var(--whitePrimary) !important;}
.iconTxtBox:hover .iconTxt { opacity: 1; color: var(--whitePrimary) } 

.resIconTxt {margin: 4px 0 0 0; padding: 0; font-size: 10px; color: var(--whiteBg); border-bottom: 3px solid transparent; overflow: hidden;
    text-overflow: ellipsis; width:100%;
    white-space: nowrap; text-align:center;}
.iconTxtBox.active >.resIconTxt {opacity: 1; color: var(--whitePrimary) !important;}
.iconTxtBox:hover .resIconTxt { opacity: 1; color: var(--whitePrimary) }

.iconBox24 { width: 24px; height: 24px;}
.showPopupBox {overflow: unset !important;opacity:1 !important;}

.iconBox24 img { width: 100%; height: 100%;}
/* .iconTxt {margin: 4px 0 0 0; padding: 0; font-size: 10px; color: rgba(255, 255, 255, 0.5); border-bottom: 3px solid transparent; overflow: hidden;
    text-overflow: ellipsis; width:100%;
    white-space: nowrap; text-align:center; } */
.iconTxt { display: none; }
.iconTxtBox:last-child { margin-right: 0 !important; padding:0;}

.vadScreen {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
}
.vadHide {
    display: none;
}

 .talkerGreen {
    outline: 3px solid #00ff00;
    outline-offset: -3px;
}
/*
.talkerGreen::after{
    content: '';
    position: absolute;
    
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    left: 0;
    top: 0;
    border: 3px solid green;
    background-color: transparent;
} */

/* .videoElm {
    outline: 3px solid #00ff00;
    outline-offset: -3px;
} */


.resolutionBlock {z-index: 1; position: absolute !important; top:0; background-color: rgba(0,0,0,0.54); border-radius: 4px; padding: 0 8px 0 24px !important;}
.resolutionBlock small {font-size:12px !important; margin-top:0 !important;}
.resolutionBlock>.iconTxtBox {height:40px !important;}

.keepCntr {position: absolute !important; left: 50%; transform: translate(-50%, -50%); top: 50%; z-index: 1;}
.keepRight {position: absolute !important; right: 0%; z-index: 1; top: 50%; transform: translateY(-50%);}
/* .keepRight .iconTxtBox {margin-right:24px;} */
.openConfMenu { display: none; }
/* .openConfMenu { display: none; position: absolute; max-width: 288px; min-height: auto; background-color: rgba(255,255,255,0.92); bottom: 48px; right: 24px; margin-bottom: 24px; box-shadow: 2px 2px 38px rgba(0,0,0,0.38); z-index: 10; }
.openConfMenu:after { content:""; position: absolute; z-index: 0; width: 0; height: 0; border-top: 12px solid rgba(255,255,255,0.92); border-left: 12px solid transparent; border-bottom: 12px solid transparent; border-right: 12px solid transparent; bottom: -23px;right: 16px; }
.openConfMenu.slideUp { display: flex; } */
.confOptionsBlock {display: flex; flex-wrap: wrap; padding: 8px 16px; }
.confOption { display: flex; opacity:0.64; flex: 0 0 auto; flex-direction: column; align-items: center; justify-content: center; margin: 0 12px; border-bottom: 3px solid transparent; cursor: pointer; position: relative;min-width:56px; height: 54px;}
.confIconBox24 {width: 24px; height: 24px;}
.confIconBox24 img { width: 100%; height: 100%;}
.confIconTxt {margin: 4px 0 0 0; padding: 0; font-size: 10px; color: var(--blackTxtPrimary);  }
.confIconTxt:last-child { margin-right: 0 !important;}
/* .confIconTxt:hover { opacity: 1; border-bottom: 3px solid var(--primaryColor);}
.confIconTxt.active { opacity: 1; border-bottom: 3px solid var(--primaryColor);} */

/**** For Mobile Responsive Bottom Control *******/
.confOption:hover .confIconTxt { opacity: 1; color: #fff}
.confOption:hover { opacity: 1; border-bottom: 3px solid var(--primaryColor);}
.confOption.active {opacity: 1;border-bottom: 3px solid var(--primaryColor);}
.confOption.active>.confIconTxt {opacity: 1; font-weight:500; color: var(--blackTxtPrimary) !important;}


.openMeetingId { position: absolute; min-width: 288px; min-height: auto; background-color: rgba(0,0,0,0.87); bottom: 72px; right: 64px; margin-bottom: 24px; box-shadow: 2px 2px 38px rgba(0,0,0,0.38); z-index: 10; -moz-transition: all 0.3s ease; -webkit-transition: all 0.3s ease; transition: all 0.3s ease; height: 0; opacity: 0; visibility: hidden; }
/* .openMeetingId:after { content: ""; position: absolute; z-index: 0; width: 0; height: 0; border-top: 8px solid rgba(0,0,0,0.87); border-left: 8px solid transparent; border-bottom: 8px solid transparent; border-right: 8px solid transparent; bottom: -16px; left: 16px; } */
.openMeetingId.slideUp {  display: flex; height: auto; visibility: visible; opacity: 1;  }

.meetingInfoContainer {display: flex; flex-direction: column; padding:8px 16px; width:100%;}
.meetingInfoHeader {display:flex; align-items:center;}
.meetingHeaderTxt {flex:1 1 auto;}
.meetingHeaderTxt p {margin:0; color:rgba(255,255,255,0.72);}
.meetingHeaderAction {flex:0 0 auto;}
.meetingHeaderAction svg {fill:rgba(255,255,255,0.72) !important;}
.meetingHeaderAction button:hover {background-color:rgba(255, 255, 255, 0.08);}
.meetingIdBlock {border:1px solid rgba(255,255,255,0.18); margin:16px 0 0 0; border-radius: 50px; background-color:rgba(255,255,255,0.12);}
.meetingIdBlock p {margin:8px 16px; text-align:center; color:rgba(255,255,255,0.72); font-weight:550; }
.meetingIdScanner {width: 232px; height: 232px; padding: 16px;}
.meetingIdScanner img {width: 100%; height:100%;}

/* .offIcon button:hover{background-color:rgba(89, 186, 239,0.08);} */
.offIcon svg {fill:rgba(0,0,0,0.38);}
.onIcon button:hover{background-color:rgba(89, 186, 239,0.08);}
.onIcon svg {fill:var(--primaryColor);}
.enabledButton {text-transform: capitalize;}
.diabledButton {border:1px solid rgba(0,0,0,0.38) !important; text-transform: capitalize; color:rgba(0,0,0,0.38) !important;}
.enabledTxt {color:var(--primaryColor); text-transform: capitalize}
.disabledTxt {color:rgba(0,0,0,0.38) !important; text-transform: capitalize}
.bottomBtmSm { padding: 5px !important }
.screensVideoContainer { width: 100%; height: 100%; position: relative; display: flex; background-color: #191919; align-items: center; justify-content: center; }
.screensVideoContainerWrap { flex-wrap: wrap; width: 100%; height: 100%; position: relative; display: flex; background-color: #191919; align-items: center; justify-content: center; }
.screenRow { display: inline-flex; flex: 1; background-color: #ff0000; }

/* .screenContainer:before {z-index: 2;
    content: "";
    position: absolute;
    width: 250px;
    height: 44px;
    background: url(/images/logos/instavc-white-logo.png);
    background-size: cover;
    right: 10vw;
    bottom: 48vh;
    background-color: rgba(0,0,0,0.2);
    opacity: 0.09;
} */

.sixteen9 { width: 100%; height: 0; padding-bottom: 56.25%; background-color: #272727; position: relative; overflow: hidden; display: flex; align-items: center; justify-content: center; }
.sixteen9 > img,
.sixteen9 > video { position: absolute; z-index: 1; width: 100%; object-fit: cover; top: 0; left: 0; height: 100%; }
.sixteen9 > .pName { position: absolute; z-index: 2; left: 0; top: 0; background-color: rgba(0,0,0,0.5); padding: 8px 16px; color: #fff; font-size: 0.8rem; }

.failedMeida { display: flex; position: absolute; left: 0; top: 0; bottom: 0; right: 0; align-items: center; justify-content: center; z-index: 1; }

.stngBlock { display: flex; flex: 1; flex-direction: column;}
.stngBlock > .headerSt { padding: 12px 16px; display: flex; flex: 0 0 auto; height: 40px; border-block-end: 1px solid rgba(0,0,0,0.12); box-sizing: border-box}
.stngBlock > .bodySt { display: flex; flex: 1 1 auto; padding: 0 16px;}

.headerStTextBLock {display: flex; flex: 1 1 auto;  align-items: center;}
.headerStTextBLock > h3 { font-size: 1px; font-weight: 500; margin: 0; padding: 0;}
.headerStActionBlock {display: flex; align-items: center; justify-content: center; cursor: pointer;}

.stContentBLock {display: flex; flex-direction: column;}
.stTabsBlock {display: flex;}
.stFormBlock {display: flex; flex-direction: column; min-height: 128px; box-sizing: border-box;}
.nominHeight {min-height: unset !important;}
.tabContentBlock {display: flex; flex-direction: column;}
.tabContentBlock > div {display: flex; margin: 16px 0 0 0;}
.stFormFlexRow {display:flex; align-items: center;}
.stBtnBlock {display: flex; justify-content: flex-end; padding: 16px 0; align-items: flex-end; flex: 1 1 auto;}
/* .stRadioBlock {margin: 0!important; 
    padding: 3.5px 0; width: 50%; flex:1 1 auto;}
.stRadioBlock input {text-align: end;}
.stRadioBlock > span:nth-child(2) {display: flex; flex: 1 1 auto;} */

.chatBlock { display: flex; flex-direction: column; position: fixed; z-index: 25; width: 432px; background-color: var(--mainBg); right: 0; top: 0; height: 100%; -webkit-transition: all 0.3s ease; transition: all 0.3s ease; -webkit-transform: translateX(105%); transform: translateX(105%);}
.participantsBlock { display: flex; flex-direction: column; position: fixed; z-index: 1024; width: 432px; background-color: var(--whiteBg); right: 0; top: 0; bottom:0; height: 100%; -webkit-transition: all 0.3s ease; transition: all 0.3s ease; -webkit-transform: translateX(105%); transform: translateX(105%);}
.slideIn {-webkit-transform: translateX(0%); transform: translateX(0%);}
.slideDown {-webkit-transform: translateY(0%); transform: translateY(0%); }

.chatBlockHeader,
.chatBlockFooter { display: flex; flex: 0 0 auto; height: 64px; box-sizing: border-box; background-color: var(--whiteBg); align-items: center; }
.chatSearchBlock { display: flex; flex: 0 0 auto; height: 48px; box-sizing: border-box; background-color: #fbfbfb; border-bottom: 1px solid rgba(0,0,0,0.12); align-items: center; }
/* .chatBlockBody { display: flex; flex: 1 1 auto; flex-direction: column; overflow-y: auto; overflow-x: hidden; } */
.chatBlockBody {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
}
/**** Record Message ****/
.recordIconSmall svg{ fill:var(--primaryColor); border: 1px solid var(--brdrColor);
    border-radius: 50px;
    padding: 8px;}
.startRecording svg{ fill:var(--blackTransparant);}
.playpauseIcon svg {fill:var(--blackTransparant);}
/* .recordingAudioMsg {display:flex; width:100%; align-items: center;}
.recordingAudioMsg .recordMessage {display:flex; flex:0 0 auto; align-items: center; justify-content: center;}
.recordingAudioMsg .recordingWave {display:flex; flex:1 1 auto; margin: 0 16px;}
.recordingAudioMsg .sendRecording {display:flex; flex:0 0 auto;} */

.recordingAudioCenter {display: flex; flex:1; flex-direction: column;width:100%; align-items: center; justify-content: center;
    position: absolute;
    background-color: var(--basePrimary);
    z-index: 9;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;}
.recordingAudioCenter .recordMessage {display:flex; width:100%; flex-direction: column;  flex:1; margin-top:40px; align-items: center; justify-content: center; }
.recordingAudioCenter .recordingWaveBlock {display:flex; width:100%; flex:0 0 auto; height:80px; margin:16px; align-items: center; justify-content:center;}
.recordingAudioCenter .sendRecording {display:flex; width:100%; flex:1; align-items: flex-end; justify-content: center;}
.recordMessage .recordIconCircle {border: 1px solid rgba(237, 61, 63,0.24);
    border-radius: 50px;
    padding: 16px;}
.recordIconCircle svg{ fill:#ed3d3f; font-size: 48px;
    }
.audioSlider {display: flex; flex-direction: column; align-items: center; justify-content: center; margin-bottom:20px; margin-left:8px;}
.sliderProgress {width:200px;}
.audioTimer {font-size:12px; margin-bottom:4px; text-align: center; color:var(--blackTxtPrimaryInactive); }
.noChatFooter {display: none;}

/**** Audio Progress Bar Animation *****/

#bars {
    display: flex;
    justify-content: center;
    align-items: center;
/*     background: black; */
}

.bar {
    background: #008abc;
    bottom: 1px;
    height: 3px;
    width: 8px;
    margin: 0px 4px;
    border-radius: 5px;
    animation: sound 0ms -600ms linear infinite alternate;
}

@keyframes sound {
    0% {
       opacity: .35;
        height: 3px; 
    }
    100% {
        opacity: 1;       
        height: 70px;        
    }
}

.bar:nth-child(1)  { left: 1px; animation-duration: 474ms; }
.bar:nth-child(2)  { left: 15px; animation-duration: 433ms; }
.bar:nth-child(3)  { left: 29px; animation-duration: 407ms; }
.bar:nth-child(4)  { left: 43px; animation-duration: 458ms; }
.bar:nth-child(5)  { left: 57px; animation-duration: 400ms; }
.bar:nth-child(6)  { left: 71px; animation-duration: 427ms; }
.bar:nth-child(7)  { left: 85px; animation-duration: 441ms; }
.bar:nth-child(8)  { left: 99px; animation-duration: 419ms; }
.bar:nth-child(9)  { left: 113px; animation-duration: 487ms; }
.bar:nth-child(10) { left: 127px; animation-duration: 442ms; }

/***** Audio Icon Blink ******/
.iconBlink {
    animation: blinkIcon 2s steps(5, start) infinite;
    -webkit-animation: blinkIcon 2s steps(5, start) infinite;
  }
  @keyframes blinkIcon {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blinkIcon {
    to {
      visibility: hidden;
    }
  }
  /*********** End of Audio animation *****/

.chatBlockFooter { border-top: 1px solid rgba(0,0,0,0.12); }
.msgDisplayBlock { display: flex; flex: 0 0 auto; flex-direction: column; padding: 8px 16px;}
.msgRow { display: flex; flex: 0 0 auto; margin-bottom: 12px;}
.msgRowReverse { display: flex; flex: 0 0 auto; margin-bottom: 12px; flex-direction: row-reverse;}

.otherMsgBox { display: flex; flex-direction: column; position: relative; min-width: 84px; max-width: calc(100% - 240px); }
.otherMsgBox > ul { list-style-type: none; text-decoration: none; margin: 0 0 0 8px; padding: 0; display: flex; flex-direction: column; align-items: flex-start; width: calc(100% - 8px);}
.otherMsgBox > ul > li { border-radius: 4px; position: relative; background-color: var(--whiteBg); margin-bottom: 3px; padding: 0 8px; box-shadow: 1px 1px 2px rgba(0,0,0,0.12); flex: 1 1 auto; }
.otherMsgBox > ul > li:after { z-index: 1; content: ""; position: absolute; left: -8px; top: 0; }
.otherMsgBox > ul > li:first-child:after { z-index: 1; content: ""; position: absolute; left: -8px; top: 0; border-top: 6px solid #fff; border-right: 6px solid #fff; border-bottom: 6px solid transparent; border-left: 6px solid transparent; width: 0; height: 0;}

.selfMsgBox { display: flex; flex-direction: column; position: relative; min-width: 84px; max-width: calc(100% - 240px); }
.selfMsgBox > ul { list-style-type: none; text-decoration: none; margin: 0 8px 0 0; padding: 0; display: flex; flex-direction: column; align-items: flex-end; width: calc(100% - 8px);}
.selfMsgBox > ul > li { border-radius: 4px; position: relative; background-color: var(--selfMsgBg); margin-bottom: 3px; padding: 0 8px; box-shadow: -1px 1px 2px rgba(0,0,0,0.12); flex: 1 1 auto;}
.selfMsgBox > ul > li:after { z-index: 1; content: ""; position: absolute; right: -8px; top: 0; }
.selfMsgBox > ul > li:first-child:after { z-index: 1; content: ""; position: absolute; right: -8px; top: 0; border-top: 6px solid #CFEAF2; border-right: 6px solid transparent; border-bottom: 6px solid transparent; border-left: 6px solid #CFEAF2; width: 0; height: 0;}

.chatUserNameblock { display: flex; position: relative; padding: 8px 0 0 0;}
.chatMediabox { display: flex; position: relative; margin-bottom: 4px;}
.chatMediabox > p { margin: 0; padding: 0; color: var(--blackPrimary); padding: 8px 0 0 0; line-height: 22px;}
.chatMetaData { display: flex; position: relative; flex: 0 0 auto; justify-content: flex-end; margin-bottom: 2px;}
.chatMetaData > small { font-size: 11px; color: var(--blackTxtPrimaryDisable); margin: 0;}
.chatHeaderUser {display: flex; flex: 1 1 auto;}
.chatUserAvatar {position: relative;
    margin: 16px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex: 0 0 auto;
    background-color: var(--inactiveBg);
    color: var(--whitePrimary);
    display: flex;
    align-items: center;
    justify-content: center;}
.chatUserAvatar > img {width: 100%;
    height: 100%;
    border-radius: 50%;}
.chatUserText {display: flex;
    flex-direction: column;
    justify-content: center;}
.chatUserText > h3 {    font-size: 16px;
    color: var(--blackTxtPrimary);
    margin: 0;
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;}
.chatUserText > small {color: var(--blackTxtPrimaryInactive);
    margin-top: 3px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;}
    .mrgRt8 {margin-right: 8px !important;}

.mediaBox { display: flex; flex: 0 0 auto; position: relative; margin: 0px -8px -4px -8px; box-sizing: border-box; flex-direction: column; border: 4px solid #fff; border-radius: 4px; width: 320px;     height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;}
.mediaBox > img, .mediaBox > video, .mediaBox > iframe { width: 100%; height: auto; border-radius: 4px;}
.mediaBox > .metaData{ display: flex; position: absolute; bottom: 2px; right: 4px; }
.mediaBox > .metaData > small {font-size: 11px; color: var(--whiteBg); margin: 0;}

/* CSS for Access Media Denied */
.amdContainer {display: flex;
    flex: 1 1;
    z-index: 1;
    position: relative;
    overflow: auto;
    background-color: #191919;
    justify-content: space-between;}
.amdArrowBlock {display: flex; position: absolute;}
.amdArrowBlock > img {width: 104px; height: 104px;}
.amdMainBlock {display: flex; flex-direction: column;}
.amdTopBlock {display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 24px;}
.amdHeadBlock {display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;}
.amdImgBlock {display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    width: 104px;
    height: 104px;
    background-color: rgba(255,255,255,0.20);}
.amdImgBlock > img {width: 70%;
    height: 70%;
    border-radius: 50%;}
.amdTopHeading {    display: flex;
    margin-left: 24px;}
.amdTopHeading > h3 {margin: 0;
    padding: 0;
    color: #ffffff;
    font-size: 48px;}
.amdContentBlock {display: flex;
    padding: 16px 0 0 0;}
.amdLeftBlock {width: 50%;
    display: flex;
    flex-direction: column;
    margin-left: 72px;}
.amdHeading {display: flex; margin-bottom: 8px;}
.amdHeading > h3 {margin: 0; padding: 0; color: rgba(255, 255, 255, 0.7); font-size: 20px; font-weight: 500;}
.amdListBlock {display: flex;}
.amdListBlock > ul {display: flex;
    margin: 16px 0 0 16px;
    padding: 0;
    flex-direction: column;}
.amdListBlock > ul > li {margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    line-height: 22px;}
.amdRightBlock {display: flex; width: 50%; flex-direction: column; margin-left: 72px;}
.amdBottomBlock {display: flex;
    flex: 0 0 auto;
    background-color: #272727;
    padding: 24px;
    min-height: 160px;
flex-direction: column;
}
.amdBottomInner {display: flex;
    align-items: center;}
.amdBottomInner > p {margin: 0;
    padding: 0;
    color: #ffffff;}
.amdBottomInner span {color: #ffffff;}
.amdBottomInner .amdLinks {color: #00aafa;
    font-weight: 550;
    margin-left: 8px;}
.amdBtnConf {margin-left: 16px;}
.amdCameraHint {display: flex;
    align-items: center;
    padding: 0 16px;
    border-left: 1px solid rgba(255,255,255,0.38);
    margin-left: 16px;
}
.amdCameraHint > img {width: auto;
    height: auto;
    margin-right: 16px;}
.amdCameraHint > span {font-size: 12px; color: rgba(255, 255, 255, 0.7);}


.labelLeft {flex: 0 0 auto; margin-right: 32px; min-width: 112px; color:rgba(0,0,0,0.54);}
.valueRight {flex: 1 1 auto;}

.hourGlassContainer {width:300px; height:300px;}
.searchHostWrapper>div>div:first-child {margin:16px !important;}
.hostDetailsBlock p {margin:0 16px;}
/* .hourglassContentBlock {display: flex; box-sizing: border-box; align-items: center; justify-content: center; overflow-x: hidden;} */
.hostDetailsBlock {display: flex; flex: 1; padding: 16px 32px; align-items: center; justify-content: center; flex-direction: column;}
.hostDetailHeader {padding-bottom: 8px; color:rgba(0,0,0,0.54); font-size: 14px; width:100%;}
.hostDetailHeader p {font-size: 16px;}
.hostDetailHeader a {color:var(--primaryColor);}
.copyText {width: 100%;}
.copyText input{color: var(--primaryColor);}
.copyButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px;
    margin:0; cursor: pointer;
}
.copyButton img {width: 20px; height: 20px;}

.hostPwdIconCircle {display:flex; flex:0 0 auto; border-radius: 50%; align-items: center; justify-content: center; }
.hostPwdInput {width: 100%;}
.hostPwdInput span {padding-bottom: 8px; color:rgba(0,0,0,0.54); font-size: 14px; width:100%;}
.hostPwdIconCircle svg {font-size: 20px !important; fill: rgba(0,0,0,0.54);}

.invtSocialBlock {display: flex; width: 100%; align-items: center; justify-content: space-between; margin-top: -12px; flex: 1;}
.invtSocialBlock > p {margin: 0;}
.invtBtnBlock {display: flex;}
.invtBtnBlock span {padding: 0;}
.invtBtnBlock img {width: 24px; height: 24px;}
.inviteDetailsBlock {display: flex; flex: 1; padding: 16px 32px; align-items: center; justify-content: center; flex-direction: column;}

.chipListGroup { display: flex; flex-direction: column; justify-content: center; padding: 16px 16px 8px; background-color: var(--chipBg); border-radius: 4px;}
.chipListBlock {display: flex; flex-wrap: wrap;}
.chipListBlock > ul {list-style-type: none; padding: 0; margin: 0; display: flex; flex-wrap: wrap;}
.chipListBlock > ul >li {list-style-type: none; padding: 0; margin: 0 8px 8px 0; display: flex;}

.prsntLeftBlock {
    position: relative;
    display: flex;
    width: calc(100% - 420px);
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 80px);
    z-index: 2;
}

.prsntLeftBlock iframe {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border: 0;
}

.prsntRightBlock {
    width: 420px;
    position: fixed;
    background-color: rgba(0,0,0,0.54);
    top: 0;
    right: 0;
    height: calc(100vh - 80px);
    z-index: 1;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    box-sizing: border-box;
}

.mediaWrapper {
    flex: 0 0 auto;
    padding: 0px 0;
    background-color: #0f0f0f;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;
    width: 420px;
}

.setTop {
    background-color: #333;
    height: auto;
    overflow: hidden;
    position: relative;
    order: -1 !important;
    width: 420px;
    cursor: pointer;
}

/* .setTop .videoElm {
    width: 100%;
    height: unset;
} */

.inlineMeida210 {
    /* margin-right: 4px; */
    background-color: #333;
    width: 210px;
    height: 160px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

/* .inlineMeida210 .videoElm {
    width: 100%;
    height: 100%;
} */

.video16x9 {
    display: flex;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    background-color: #191919;
    position: relative;
    overflow: hidden;
}
.video16x9 video,
.video16x9 img,
.video16x9 iframe {
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 1;
}

/********* Picture in Picture Mode CSS ***********/
.pnpMsgScreen { position: absolute; 
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    z-index: 2;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.38); width: 100%;
    background: url(/images/banners/conf-moderator-blurred.jpg); 
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
}
.pnpImage {
    position: absolute; 
    top: 0;
    left: 0;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.38); width: 100%;
    padding-bottom: 56.25%;
    background: url(/images/banners/conf-moderator.png); 
    filter: blur(8px);
    background-repeat: no-repeat;
    background-size: cover;}
.pnpIcon {
    background: url(/images/icons/pip48-bs.png) center top;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 130px;
    width: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-repeat: no-repeat;
}
.pnpIcon img {width: 64px; height: auto; z-index:2;}
.pnpIcon p {color: rgba(255,255,255,0.7); text-align: center; font-size: 1.2vw;
    padding: 32px 0;}

.mixVideo {
    display: block;
    position: fixed;
    width: 100px;
    height: 56px;
    right:0;
    bottom:95px;
    z-index: 9999;
    cursor: pointer;
    overflow: hidden;
}

.mixVideoH {
    display: none;
}

.blurVideo video {
    -webkit-filter:blur(30px);
    -ms-filter: blur(30px);
    -o-filter: blur(30px);
    filter:blur(30px);
}

.layoutOverlay {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 1001;
    display: flex;
    background: rgba(0,0,0,0.97);
}
.layoutOverlay video{
    width: 100%;
    height: 100%;
}
.overlayWhite {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 3;
    display: flex;
    background-color:#f7f8fa;
}
.overlayNormalText {flex: 0 0 auto; width:100%;}
.overlayNormalText h3{font-size: 40px; text-align: center; font-weight: 450; color: var(--blackTxtPrimary); margin-top: 8px;}
.browsingUrl {width:100%; margin-top: 24px; background-color:#fff;}

.moreScreenShareContainer { display: flex; width: 100%; height: 100%; flex-direction: column; }

.screensTileBlock { display: flex; flex: 0 0 auto; height: 104px; align-items: center; width: 100%; box-sizing: border-box; padding: 0 40px; overflow: hidden }
.tilesGroup { display: flex; width: 100%; height: 100%; padding: 0; margin: 0; list-style-type: none; overflow-x: auto; overflow-y: hidden; align-items: center; }
.tilesList { cursor: pointer; width: 114px; height: 64px; border: 1px solid transparent; display: flex; flex: 0 0 auto; position: relative; }
.tilesList.activeTile { border: 1px solid var(--primaryColor); }
.tilesList video, .tilesList img { width: 100%; height: 100%; }
.mainScreenBox { display: flex; width: 100%; height: calc(100% - 104px); }
.mainScreenBox video { width: 100%; height: 100%; }
.tilesList:first-child:after {content: "First"; position: absolute; background-color: rgba(0,138,188,0.7); width: auto; height: 32px; font-weight: 500; display: flex; align-items: center; justify-content: center; padding: 0 8px; color: var(--whitePrimary); font-size: 0.875rem; line-height: 22px; z-index: 1;}
.tilesList:last-child:after {content: "Last"; position: absolute; background-color: rgba(0,138,188,0.7); width: auto; height: 32px; font-weight: 500; display: flex; align-items: center; justify-content: center; padding: 0 8px; color: var(--whitePrimary); font-size: 0.875rem; line-height: 22px; z-index: 1; right: 0; }

.hideScreensTileBlock { display: none; }
.scaleFullHW { width: 100%; height: 100%; }



/* .hostIcon {fill:#ffa000 !important; font-size: 12px !important; background-color: rgba(255,255,255,0.5); border-radius: 50%; padding:
2px;}
.hostIcon:hover {background-color: #fff;} */
.hostBadge {background-color: transparent!important;}
.hostIconActive {fill:#ffa000 !important;}
.hostIcon {fill:rgba(255,255,255,0.5) !important;}
/* .hostIcon:hover {fill:#ffa000 !important;} */
.hostIconBox {display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right:0 !important;cursor: pointer;
    opacity:1;}

/***** Video Sync *****/
.vsyncIconBlock {display:flex; flex:0 0 auto; align-items: center; justify-content: center;}
.vsyncInputBlock {display:flex; flex:1 1 auto; flex-direction:column; align-items:center; justify-content:center;}
.vsyncCentreIcon { position: relative; display: flex; align-items: center; justify-content: center; width: 128px; height: 128px; margin-top:32px;}
.vsyncCentreIcon img{width: 100%; height: auto;}
.vSyncNormalText {flex: 0 0 auto; width:100%;}
.vSyncNormalText h3{font-size: 40px; text-align: center; font-weight: 450; color: var(--blackTxtPrimary); margin-top: 8px;}
.vSyncNormalText .waitingTxt {font-size: 14px; text-align: center; font-weight: 450; color: var(--blackTxtPrimary); margin-top: 8px;}

.overlayCenterBlock { padding: 0; list-style: none; display: flex; align-items: center; justify-content: center; position: relative; top: 0; flex-direction: column; flex: 1;} 
.overlayCenterIcon { position: relative; display: flex; align-items: center; justify-content: center; width: 128px; height: 128px;}
.overlayCenterIcon img{width: 100%; height: auto;}
.opacity54 {opacity: 0.54;}

.overlayCenterText {flex: 0 0 auto;}
.overlayCenterText h2{font-size: 56px; text-align: center; font-weight: 500; color: var(--whitePrimary); margin-top: 8px;}
.overlayCenterText h3{font-size: 40px; text-align: center; font-weight: 450; color: var(--whitePrimary); margin-top: 8px;}
.overlayCenterText p {color: var(--whitePrimaryInactive); text-align:center; margin:8px 0 0;}
.overlayCenterButton {border-radius: 99px; position: relative; display: inline-flex; width: 100%; margin-top: 60px; align-items: center; justify-content: center; text-transform: uppercase; font-size: 14px; font-weight: bold; outline: none; border: none; }
.overlayCenterButton a {border-radius: 99px;}

.presenterContainer {
    display: flex;
    flex:1;
    position: relative;
}
.presenterLeftInner {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
}

.freeBlock {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,138,188,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
}

.hideDisableImage{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: none;
}

.blockWindow{
    width: 100%;
    height: 100%;
    position: absolute;
}

.blockWindow .message{
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.hideEnableImage{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: block;
}
.capitalizetxt{ padding:4px 16px !important;
    text-transform: capitalize !important;

}
.testAudioBlock {display: flex; align-items: center; position: relative; margin-top: 8px;}
.testAudioIcon {flex: 0 0 auto; display: flex; z-index: 1;}

/* .testAudioSetting {flex: 1 1 auto; z-index: 2; position: absolute; -webkit-transform: rotate(270deg); -moz-transform: rotate(270deg); -o-transform: rotate(270deg); transform: rotate(270deg); width: 24px; height: 10px; top: 0px; }
.testAudioSetting .meterBlockVerticle {display: flex; align-items: center; padding: 0; flex-direction: column;  border-radius: 99px; width: 24px; height: 10px;}
.testAudioSetting .meterBlockVerticle > meter {display: flex; background: #ffffff; border-radius: 99px; width: 24px; height: 10px;} */

.testAudioSetting {flex: 1 1 auto; z-index: 2; position: absolute; -webkit-transform: rotate(270deg); -moz-transform: rotate(270deg); -o-transform: rotate(270deg); transform: rotate(270deg); width: 24px; height: 10px; top: 0px; left: calc(50% - 12px);}
.testAudioSetting .meterBlockVerticle {display: flex; align-items: center; padding: 0; flex-direction: column;  border-radius: 99px; width: 24px; height: 10px; justify-content: center; background-color: var(--whitePrimary); overflow: hidden; }
.testAudioSetting .meterBlockVerticle > meter {display: flex; border-radius: 99px; width: 24px; height: 20px;}

/**** For New Conf2020 Meter****/
.micAudioAnimation {flex: 1 1 auto; z-index: 2; position: absolute; -webkit-transform: rotate(270deg); -moz-transform: rotate(270deg); -o-transform: rotate(270deg); transform: rotate(270deg); width: 22px; height: 5px; top: -3px; left: calc(50% - 12px);}
.micAudioAnimation .micMeterVerBlock {display: flex; align-items: center; padding: 0; flex-direction: column;  border-radius: 99px; width: 12px; height: 6px; justify-content: center; background-color: var(--whitePrimary); overflow: hidden; }
.micAudioAnimation .micMeterVerBlock > meter {display: flex; border-radius: 99px; width: 14px; height: 14px;}


.meterBlockVerticle meter::-moz-meter-bar  {background: transparent; border-radius: 99px;} /*background color of bar*/
.meterBlockVerticle meter::-webkit-meter-bar  {background: transparent; border-radius: 99px;} /*background color of bar*/
.meterBlockVerticle meter::-webkit-meter-optimum-value {background-image: linear-gradient(to right ,#80ff00 70%, #ffbf00); border-radius: 99px;}
.testAudioSetting .overBarVerticle { display: flex; flex: 1; align-items: center; justify-content: space-between; position: absolute; top: 50%; left: 0; width: 100%; transform: translateY(-50%); border-radius: 99px;}
.testAudioSetting .overBarVerticle > .pidVerticle { display: flex; box-sizing: border-box; background: transparent; border: 0px solid #fff; border-radius: 99px; }
.WhiteCircle {border: 1px solid rgba(255, 255, 255, 0.7) !important;}
.responsiveCtrl {display:none;}
.qrBlock { display:flex; align-items: center; justify-content: center;}
.qrcode {width: 200px; height:auto;}
.responsiveBtmControls {display:none;}
.confModeTxt {position: absolute; background-color: rgba(0,0,0,0.38); z-index:1; left:0px; right:0; bottom:8px;  padding:4px 8px; display: flex;
    align-items: center;
    justify-content: center;}
.confModeTxt span {font-size:12px; color:#fff;}
.noPreview {display:none;}
.fullOpacity {opacity:1 !important;}

.participantsListActionBlock{position:absolute; bottom:0; left:0; right:0; z-index:1; background-color: #fafafa; display: flex; border-top: 1px solid rgba(0,0,0,0.12); align-items: center; justify-content: center; padding: 8px 0;}
.participantsListActionBlock button {margin:2px; font-size: 10px}

.inviteContainer {display:flex; overflow-x:hidden; overflow-y:auto; flex:1;}
.rightFooterBtns{position:relative; background-color: #fafafa; display: flex; border-top: 1px solid rgba(0,0,0,0.12); align-items: center; justify-content: center; padding: 8px 0;}
.rightFooterBtns button {margin:8px;}

/******* Audio Call Animation **********/
.patientCallContainer { position: fixed;
    /* padding: 8px 16px; */
    top: 0px;
    margin: 0;
    background-image: linear-gradient( #008ABC, #00456D);
    z-index: 2;
    width: 100vw;
    bottom: 80px;}

.rippleContainer { position: relative; margin: 0px auto; height: 100%; display: flex; align-items: center; align-content: center; justify-content: space-between; flex-direction:column; }
.rippleBlock { height: inherit; flex-direction: column; padding: 24px 0; box-sizing:border-box; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: space-between; flex:1;}
.imageCircle{margin: 120px 184px 98px ; display: flex; }
.circleRipple { background-color: #f5f5f5; width: 120px; height: 120px; border-radius: 50%; -webkit-animation: ripple 0.7s linear infinite; animation: ripple 0.7s linear infinite; border: 2px solid rgba(255,255,255, 0.3); align-items:center; justify-content: center;overflow:hidden; }
.circleRipple>img {width: 100%; height: 100%; object-fit: cover; border-radius: 50%; display: block; }

.talkVideo {
    
    height: 100%;
}
@-webkit-keyframes ripple {
  0% {-webkit-box-shadow: 0 0 0 0 rgba(205,69,51, 0.12), 0 0 0 1em rgba(205,69,51, 0.12), 0 0 0 3em rgba(205,69,51, 0.12), 0 0 0 5em rgba(205,69,51, 0.12);
       box-shadow: 0 0 0 0 rgba(205,69,51, 0.12), 0 0 0 1em rgba(205,69,51, 0.12), 0 0 0 3em rgba(205,69,51, 0.12), 0 0 0 5em rgba(205,69,51, 0.12);
     }
  100% {-webkit-box-shadow: 0 0 0 1em rgba(255,255,255, 0.06), 0 0 0 3em rgba(255,255,255, 0.06), 0 0 0 5em rgba(255,255,255, 0.06), 0 0 0 8em rgba(255,255,255, 0);
         box-shadow: 0 0 0 1em rgba(255,255,255, 0.06), 0 0 0 3em rgba(255,255,255, 0.06), 0 0 0 5em rgba(255,255,255, 0.06), 0 0 0 8em rgba(255,255,255, 0);
       }
}
@keyframes ripple {
  0% { -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.12), 0 0 0 1em rgba(255,255,255, 0.12), 0 0 0 3em rgba(255,255,255, 0.12), 0 0 0 5em rgba(255,255,255, 0.12);
        box-shadow: 0 0 0 0 rgba(255,255,255, 0.12), 0 0 0 1em rgba(255,255,255, 0.12), 0 0 0 3em rgba(255,255,255, 0.12), 0 0 0 5em rgba(255,255,255, 0.12);
     }
  100% {-webkit-box-shadow: 0 0 0 1em rgba(255,255,255, 0.06), 0 0 0 3em rgba(255,255,255, 0.06), 0 0 0 5em rgba(255,255,255, 0.06), 0 0 0 8em rgba(255,255,255, 0);
         box-shadow: 0 0 0 1em rgba(255,255,255, 0.06), 0 0 0 3em rgba(255,255,255, 0.06), 0 0 0 5em rgba(255,255,255, 0.06), 0 0 0 8em rgba(255,255,255, 0);
       }
}

.audioTimerCount {display:flex; flex:0 0 auto; margin-top:24px;}
.audioTimerCount h2 {font-size: 16px; font-weight:550; color: var(--whitePrimaryInactive);}
.audioUserName {margin-bottom:48px;}
.audioUserName h1 {font-size: 32px; font-weight:400; color: var(--whitePrimaryInactive); text-transform: capitalize; }
.hozAvatarList {display: flex; margin-bottom:64px; width:100%; padding:0 16px;}
.hozAvatarList ul {display: flex; flex:1; padding:0; margin:0; align-items: flex-start; justify-content: center;}
.hozAvatarList ul li {display:flex; box-sizing:border-box; padding:0 8px;}

.hozAvatarList ul li>.hozListItem {padding: 0; margin: 0; display: flex; flex-direction: column; align-items: center; position: relative;}
.hozListItem .hozListAvatar { position: relative; margin-bottom: 8px; width: 56px; height:56px; border-radius: 50%; flex: 0 0 auto; background-color: var(--inactiveBg); color: var(--whitePrimary); display: flex; align-items: center;  justify-content: center; }
.hozListItem > .hozListAvatar > .statusOnline { position: absolute; z-index: 1; width: 8px; height: 8px; background-color: #1bbf46; border-radius: 50%; bottom: 2px; right: -5px; border: 3px solid #fff; }
.hozListItem > .hozListAvatar > .statusOffline { position: absolute; z-index: 1; width: 8px; height: 8px; background-color: #929292; border-radius: 50%; bottom: 2px; right: -5px; border: 3px solid #fff; }
.hozListItem > .hozListAvatar small {padding: 0; margin: 0; font-weight: bold; font-size: 14px;}
.hozListItem .hozListAvatar img { width: 100%; height: 100%; border-radius: 50%; object-fit: cover; }
.hozListTitle { padding:0; text-overflow: ellipsis; overflow: hidden; white-space:nowrap; width:48px;}
.hozListTitle h3 {text-align:center; text-transform: capitalize; font-size:10px; color: var(--whitePrimaryInactive); text-overflow: ellipsis; overflow: hidden; white-space: nowrap;}
.hozListMore {background-color:#fff !important; cursor:pointer; color: var(--primaryColor) !important;}
.hozListMore span {font-weight: 600; font-size:18px;}
.countBadge {position: absolute;
    top: -4px;
    right: -4px;
    background-color: orange;
    border-radius: 50%;
    padding: 8px;
    width: 16px;
    height: 16px;}
.countBadge img {width:100%; height: 100%;}
.orangeBadge span {background-color: var(--secondaryBg); right: 16px;
    top: 4px;}
.audiobottomControls {  position: relative; z-index: 4; display: flex; flex: 0 0 auto; height: 80px; background-color: #00456D; border-top: 1px solid rgba(255,255,255,0.12); justify-content: space-between;}
.audioIconCtrls { display: flex; position: relative; cursor: pointer; flex:1; justify-content:flex-start; height:80px;}
.recordingTimer span{color: #fff; font-size: 14px; font-weight:500;}
.unmutePopup { background-color:var(--secondaryBg); padding: 16px; min-width:320px;
    border-radius: 50px; position:absolute; bottom:72px; text-align:center; box-shadow: 0 0 24px rgba(0,0,0,0.25);
    box-sizing: border-box; }
.unmutePopup p {font-size: 12px; color: var(--whitePrimary); padding:0; margin:0; font-weight:550;}
.unmutePopup:after { content: ""; position: absolute; z-index: 0; width: 0; height: 0; border-top: 8px solid var(--secondaryBg); border-left: 8px solid transparent; border-bottom: 8px solid transparent; border-right: 8px solid transparent; bottom: -16px; left: calc(50% - 8px) ; }

.invertBlack   { -moz-filter: brightness(0) invert(0); -webkit-filter: brightness(0) invert(0); filter: brightness(0) invert(0); }

/****** Lock options Slide Down *****/
.dropMenuOptions { 
    position: absolute;
    min-width: 16px;
    max-width: 240px;
    min-height: 16px;
    background-color: var(--blackPrimary);
    top: 80px;
    right: 0;
    left:16px;
    margin-top: 16px;
    box-shadow: 2px 2px 38px rgba(0,0,0,0.38);
    z-index: 20;
    border-radius: 3px;
    display: none;
    border: 1px solid rgba(255,255,255,0.12);
}

.dropMenuOptions:after { content:""; position: absolute; z-index: 0; width: 0; height: 0; border-top: 12px solid transparent; border-right: 12px solid transparent; border-bottom: 12px solid var(--blackPrimary); border-left: 12px solid transparent; top: -24px; right: 16px; }
.dropMenuOptions.slideDown { display: flex; }

.whiteDivider {background-color: rgba(255,255,255, 0.25);}

.blackListMenu { display: flex; flex: 1; flex-direction: column; overflow-x: hidden; overflow-y: auto;}
.blackCustomList {padding: 8px 0; position: relative; list-style: none; margin:0;}
.blackCustomListItem {
padding:4px 16px; width: 100%;
display: flex;
position: relative;
box-sizing: border-box;
text-align: left;
align-items: center;
justify-content: flex-start;
text-decoration: none;}
.blackCustomListItem:hover{cursor:pointer; text-decoration: none; background-color: rgba(255,255,255, 0.06);}
.blackCustomListItemIcon {color: rgba(255,255,255, 0.87);
    display: inline-flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center; margin-right:8px;}
.blackCustomListItemIcon img,.blackCustomListItemIcon svg {width:20px; height:20px;}
.blackCustomListItemText {flex:1;}
.blackCustomListItemText p {color:rgba(255,255,255,0.87);font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em; margin:0;}
.listSubheader {color:var(--whitePrimaryInactive); padding:8px 16px; font-size:0.75rem; font-weight:550;}
.blackCustomListItem {
    background-position: center;
    transition: background 0.8s;
  }
  .blackCustomListItem:hover {
    background: rgba(255,255,255, 0.12) radial-gradient(circle, transparent 1%, rgba(255,255,255, 0.12) 1%) center/15000%;
  }
  .blackCustomListItem:active {
    background-color: #000000;
    background-size: 100%;
    transition: background 0s;
  }

.lobbyBlock { display: flex; flex-direction: column; width: 100%; padding: 0px; flex: 1; }
.lobbyHeaderBlock { display: flex; width: 100%; align-items: center; margin-top:16px; padding:0 16px; box-sizing: border-box;}
.lobbyHeaderBlock .infoTxt { font-size: 0.875rem; color: var(--blackLight); font-weight: 400; display: flex; flex: 1;}
.lobbyHeaderBlock .actionBlock { min-width: 40px; height: 40px; flex: 0 0 auto; align-items: center; justify-content: center; display: flex; cursor: pointer;} 
/* .lobbyHeaderBlock .actionBlock span { padding: 0; margin: 0; font-size: 1.0rem; font-weight: 500; color: var(--primaryColor); } */
.handleMrgn { margin: 0 !important;}
.handlePdng { padding: 0 !important;}


.knockIconBlock {position: absolute !important; right:0px; top:0px; z-index: 1; display: flex;}
.knockIconBlock>.iconTxtBox {height:40px !important;}
.knockIconBlock>.iconTxtBox:last-child {padding: 4px 16px;}


.snackBarBlock { display: flex; flex-direction: column; position: fixed; z-index: 9; width: 375px; right: 4px; bottom: 84px; min-height: 56px; max-height: 80%; -webkit-transition: all 0.3s ease; transition: all 0.3s ease; height: 0; opacity: 0; visibility: hidden; }
.snackBarHeading {display: flex; align-items: center; flex: 0 0 auto; padding:0; height: 56px; position: sticky; top: 0; z-index: 1; background-color: var(--whitePrimary); border-bottom: var(--brdrDark); align-items: center; }
.snackBarHeadingTitle {display: flex; flex:1; padding:8px 16px; min-width: 0; }
.snackBarHeadingAction {display: flex; align-items: flex-end;}
.snackBarHeading h3 { color: var(--blackPrimay); font-size: 1.25rem; font-weight: 400; line-height: 1.6; letter-spacing: 0.0075em;}
.visibleTrans { height: unset; visibility: visible; opacity: 1;}

.videoDisableAvatar { display: flex; width: 56px; height: 56px; border-radius: 50%; background-color: rgba(255,255,255,0.24); position: absolute; left: 50%; top: 50%; z-index: 1; transform: translate(-50%, -50%); align-items: center; justify-content: center; }
.videoDisableAvatar span { font-size: 1.0rem; color: var(--whiteBg); }
.videoDisableAvatar img {width:100%; height: 100%; border-radius: 50%;} 

/******* DOcument Share *****/
.documentShareContainer {display: flex;
    flex-direction: column;
    padding: 16px; flex:0 0 auto;}
.documentUploadContainer {padding:24px 16px; background-color: var(--basePrimary); flex:0 0 auto;}
.docListBlock {flex:0 0 auto; padding:16px !important;}
.listItemBg { background-color: var(--basePrimary); }

/****Subtitles*****/
.subtitlesBlock {display: flex; z-index:99; align-items: center; justify-content: center; position: absolute; bottom:88px; left:0; right:0; }
.usercc {display: flex; flex-direction: column; align-items: center; justify-content: center; padding:0; margin:0 16px;}
.talkUser {color:var(--primaryColor); font-size: 0.875rem; font-weight: 550; margin-right:8px;}
.talkTxt {color:rgba(255,255,255,0.87); font-size: 1.00rem; font-weight:500; padding:8px; margin-bottom:4px !important; line-height: 20px; border-radius: 4px; background-color: rgba(0,0,0,0.72);}
.usercc p {margin:0;}


.playAudioDivHidden {
    display: none;
}
.playAudioDiv {
    position: absolute;
    z-index: 1;
    /* background-color: red; */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.playAudioIcon {
    width: 100%;
    height: 100%;
}
/**** MEDIA QUERY FOR LARGE DEVICES TABLET ORIENTATION *****/
/**** MEDIA QUERY FOR TABLET *****/
@media (min-width: 768px) and (max-width: 1024px){
    .openConfMenu {display:flex; position: absolute; max-width: 288px; min-height: auto; background-color: rgba(255,255,255,0.92); bottom: 40px; right: 16px; margin-bottom: 24px; box-shadow: 2px 2px 38px rgba(0,0,0,0.38); z-index: 10; -moz-transition: all 0.3s ease; -webkit-transition: all 0.3s ease; transition: all 0.3s ease; height: 0; opacity: 0; visibility: hidden; }
    .openConfMenu:after { content: ""; position: absolute; z-index: 0; width: 0; height: 0; border-top: 8px solid rgba(255,255,255,0.92); border-left: 8px solid transparent; border-bottom: 8px solid transparent; border-right: 8px solid transparent; bottom: -16px; right: 50%; transform:translateX(50%); }
    .openConfMenu.slideUp {  display: flex; height: 50%; visibility: visible; opacity: 1;  }
    .mobRight{ right: 0; position: absolute !important; top: 50%; transform: translateY(-50%); padding:0 16px; }
    .bottomControls {display:none !important;}
    .responsiveBtmControls {display:flex; position: absolute;z-index: 3; flex: 0 0 auto; height: 64px; box-sizing: border-box; background-color: var(--blackTransparant); border-top: 1px solid rgba(255,255,255,0.12); justify-content: space-between; bottom: 0;
        left: 0;right: 0;}
    .confOptionsBlock { display: flex; flex-direction: column; padding: 8px 0; height: 100%; min-height: 40px; overflow-y: auto; flex-wrap: nowrap; }
    .circleMd{width: 40px; height: 40px;}
    .imageCircle {margin: 104px 92px 49px;}
    .circleRipple {width:80px; height:80px;}
    .audioUserName h1 {font-size: 24px;}
    .hozAvatarList ul{
        box-sizing: border-box;}
    .hozListItem .hozListAvatar {width:48px; height:48px;}
    .presenterLeftInner { height: 100%; justify-content: space-between; }
}


/**** MEDIA QUERY FOR LARGE DEVICES TABLET ORIENTATION *****/
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .noPreview { position: fixed; width: 100%; height: 100%; z-index: 10000; background-color:rgba(0,0,0,0.85); display: flex; flex: 1; }
    .screenRotate {padding: 0; list-style: none; display: flex; align-items: center; justify-content: center; position: relative; top: 0; flex-direction: column; flex: 1;}
    .screenRotateImg {width:120px; height:120px;}
    .screenRotateImg img {width: 100%; height:100%;}
    .screenRotateMsg {font-size: 16px; margin-top:8px; color: rgba(255,255,255,0.54); font-weight:500;}
    .screenContainer { flex-direction: column;}
    .prsntLeftBlock {width:100%; height: 50%; z-index: 4; flex: 1; }
    .prsntRightBlock { width: 100%; height: 50%; flex: 1; position: relative; }
    .screensTileBlock { padding: 0 16px; }
}

/*Tablelet*/
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    
    .prsntLeftBlock, .prsntRightBlock{ width: 50%; height: calc(100vh - 64px);}
    .screenShare{ width: 50%; height: calc(100vh - 64px);}
    .inlineMeida210{width: 100%;}
    .mediaWrapper {width: 100%;}
    .setTop {width: 100%;}
    .keepCntr{right: 50%;left: 50%;transform: unset; top: 0 !important; bottom: 0;}
    .patientCallContainer {overflow-x: hidden; overflow-y: scroll; scroll-behavior: smooth;}

    .prsntLeftBlock {width:50% !important; height: calc(100vh - 64px); z-index: 4; flex: 1; }
    .prsntRightBlock { width: 50%; height: calc(100vh - 64px); flex: 1; position: relative; }
    .screensTileBlock { padding: 0 16px; }

}


/**** MEDIA QUERY FOR SMALL DEVICES PHONES *****/
@media (min-width: 320px) and (max-width: 767px) {  
    .bottomControls {display:none !important;}
    .responsiveBtmControls {display:flex; position: absolute;z-index: 3; flex: 0 0 auto; height: 56px; box-sizing: border-box; background-color: var(--blackTransparant); border-top: 1px solid rgba(255,255,255,0.12); justify-content: space-between; bottom: 0;
        left: 0;right: 0;}

    /***** 10th April 2020 - Keerthi *****/
    .rightItemsControl {padding:0 !important;}
    /* .itemsControl { display: flex; flex:1; padding: 0 16px !important; align-items: center; justify-content: center; position: relative;} */
    .itemControl { left: 16px; bottom: 0; flex: 0 0 auto; justify-content: flex-start; top: 0; }
    .iconTxtBox{flex:1 1 auto !important; height: 56px; width: 40px; padding: 0 12px 0 0; }
    .stFormFlexRow {flex-direction: column; align-items: unset!important; }
    .noFlexCol {flex-direction: row; align-items: center!important;}
    .participantsBlock {width:100% !important;}
    /* .prsntLeftBlock {width:100% !important; height:100%; z-index:4;} */
    .prsntLeftBlock iframe {position: relative; z-index:20; height:calc(100vh - 64px);}
    .confBtns {width:48px !important; height:48px !important;}
    .noPreview {display:none;}
    .circleMd { width: 40px; height: 40px; }
    .imageCircle {margin: 104px 92px 49px;}
    .circleRipple {width:80px; height:80px;}
    .audioUserName h1 {font-size: 24px;}
    .hozAvatarList ul { box-sizing: border-box;}
    .hozListItem .hozListAvatar {width:48px; height:48px;}
    .unmutePopup {min-width:264px; bottom:62px; left:-25px;}
    .unmutePopup:after {left: calc(50% - 90px);}
    .audiobottomControls, .audioIconCtrls {height:64px;}
    .patientCallContainer {bottom:64px;}
    .audiobottomControls>.rightItemsControl {padding:0;}
    .audiobottomControls .iconTxtBox {padding:0 8px;}
    
    /* added on april 2020 */
    .screenContainer { flex-direction: column; }
    /* added on May 2020 - Keerthi */
    .confScreenContainer {flex-direction: column; }
    /* .prsntLeftBlock {width:100% !important; height: 50%; z-index: 4; flex: 1; }
    .prsntRightBlock { width: 100%; height: 50%; flex: 1; position: relative; } */
    .inlineMeida210 { width: 50%; }
    .confModeTxt { bottom: 72px}
    .confModeTxt span { font-size: 12px; color: rgba(255,255,255,0.5); }
    .inviteDetailsBlock {padding:16px;}

    .lobbyBlock { min-width: 100%;  }
    .snackBarBlock {width:100%; right:0; top:0; max-height: 100%; height: 100%;}
    .knockIconBlock {display: none;}
    .vsyncCentreIcon {width:56px; height:56px;}
    .vSyncNormalText h3{font-size: 20px;}
    .screensTileBlock { padding: 0 16px; }
    .overlayCenterIcon {width: 64px; height: 64px;}
}

/**** MEDIA QUERY FOR SMALL DEVICES PHONES ORIENTATION *****/
@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
    
    .responsiveBtmControls {display:flex; position: absolute;z-index: 3; flex: 0 0 auto; height: 64px; box-sizing: border-box; background-color: var(--blackTransparant); border-top: 1px solid rgba(255,255,255,0.12); justify-content: space-between; bottom: 0;
    left: 0;right: 0;}
    .noPreview {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color:rgba(0,0,0,0.85);
    display: flex;
    flex: 1;
    }
    /* .prsntLeftBlock {width:50% !important; height:100%; z-index:4;} */
    .prsntLeftBlock iframe {position: relative; z-index:20; height:calc(100vh - 64px);}
    /* .prsntRightBlock { width: 100%; } */
    .mediaWrapper { height: 50%; width: 100%; align-items: center; }
    .screenShare { width: 100%; height: 50%; bottom: 64px; }
    .setTop { background-color: unset; }
    .screenRotate {padding: 0; list-style: none; display: flex; align-items: center; justify-content: center; position: relative; top: 0; flex-direction: column; flex: 1;}
    .screenRotateImg {width:120px; height:120px;}
    .screenRotateImg img {width: 100%; height:100%;}
    .screenRotateMsg {font-size: 16px; margin-top:8px; color: rgba(255,255,255,0.54); font-weight:500;}
    .keepCntr { left: 50%; transform: translateX(-50%); top: 0 !important; bottom: 0; }
    .circleMd { width: 40px; height: 40px; }
    .confOptionsBlock { display: flex; flex-direction: column; padding: 8px 0; height: 100%; min-height: 40px; overflow-y: auto; flex-wrap: nowrap; }
    
    .openConfMenu {display:flex;  position: absolute; max-width: 288px;  background-color: rgba(255,255,255,1); bottom: 0px; right: 8px; margin-bottom: 0px; box-shadow: 2px 2px 38px rgba(0,0,0,0.38); z-index: 10; -moz-transition: all 0.3s ease; -webkit-transition: all 0.3s ease; transition: all 0.3s ease; height: 0; opacity: 0; visibility: hidden;  border-radius: 8px 8px 0 0; padding-bottom: 40px;}
    .openConfMenu.slideUp {  display: flex; height: calc(100% - 132px); visibility: visible; opacity: 1;  }
    /* .openConfMenu:after { content: ""; position: absolute; z-index: 0; width: 0; height: 0; border-top: 8px solid rgba(255,255,255,0.92); border-left: 8px solid transparent; border-bottom: 8px solid transparent; border-right: 8px solid transparent; bottom: -16px; right: 16px; } */
    
    .mobRight{ right: 0; position: absolute !important; top: 50%; transform: translateY(-50%); padding:0 16px; }
    /** added on 11th April 2020 ***/
    .presenterLeftInner { height: 100%; justify-content: space-between; }

    /* added on april 2020 */
    .screenContainer { flex-direction: column; }
    .confScreenContainer {flex-direction: column; }
    .prsntLeftBlock {width:100% !important; height: 50%; z-index: 4; flex: 1; }
    .prsntRightBlock { width: 100%; height: 50%; flex: 1; position: relative; }
    .inlineMeida210 { width: 50%; }
    .overlayCenterButton { margin-top: 0; }
    .confModeTxt { bottom: 72px}
    .confModeTxt span { font-size: 12px; color: rgba(255,255,255,0.5); }

    /* .openConfMenu {display: flex;
        width: 100%;
        height: 0;
        padding: 0;
        border-radius: 0;
        z-index: 1024;
        background-color: rgba(0,0,0,0.70);
        flex-direction: column;
        justify-content: flex-end;
        align-items: center; visibility: hidden; opacity: 0; display: none;}
    .openConfMenu.slideUp {  visibility: visible; opacity: 1; display: flex; height: 100%; position: fixed; z-index: 1024; }
    .stngBlock {
        width: calc(100% - 16px);
        min-height: 25%;
        display: flex;
        max-height: 50%;
        flex-direction: column;
        background-color: var(--whitePrimary);
        justify-content: flex-end;
        margin-bottom: 8px;
        border-radius: 4px 4px 0 0;
    }
    .confOption {
        height: 54px;
        display: flex;
        align-items: center;
        padding: 16px;
        box-sizing: border-box;
        width: 100%;
        flex: 0 0 auto;
        border-bottom: 1px solid var(--brdrColor);
        margin: 0;
        min-width: unset;
        flex-direction: row;
        justify-content: flex-start;
    }
    .confIconBox24 {
        margin-right: 16px;
    }
    .confIconTxt { font-size: 1.0rem;}
    .confOption.active { opacity: 1;
        border-bottom: none;
        background-color: var(--baseDark); } */


    




}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    .responsiveBtmControls {display:flex; position: absolute;z-index: 3; flex: 0 0 auto; height: 64px; box-sizing: border-box; background-color: var(--blackTransparant); border-top: 1px solid rgba(255,255,255,0.12); justify-content: space-between; bottom: 0;
    left: 0;right: 0;}
    .prsntLeftBlock, .prsntRightBlock{ width: 50%; height: 100%; }
    .screenShare{ width: 50%; height: calc(100vh - 64px); }
    .inlineMeida210 { width: 100%; }
    .mediaWrapper { width: 100%; }
    .setTop { width: 100%; }
    .keepCntr { left: 50%; transform: translateX(-50%); top: 0 !important; bottom: 0; }
    .circleMd{ width: 40px; height: 40px; }
    .confOptionsBlock { display: flex; flex-direction: column; padding: 8px 0; height: 100%; min-height: 40px; overflow-y: auto; flex-wrap: nowrap; }
    
    .openConfMenu { display: flex; position: absolute; max-width: 288px;  background-color: rgba(255,255,255,1); bottom: 0px; right: 8px; margin-bottom: 0px; box-shadow: 2px 2px 38px rgba(0,0,0,0.38); z-index: 10; -moz-transition: all 0.3s ease; -webkit-transition: all 0.3s ease; transition: all 0.3s ease; height: 0; opacity: 0; visibility: hidden; border-radius: 8px 8px 0 0;  padding-bottom: 40px; }
    /* .openConfMenu:after { content: ""; position: absolute; z-index: 0; width: 0; height: 0; border-top: 8px solid rgba(255,255,255,1); border-left: 8px solid transparent; border-bottom: 8px solid transparent; border-right: 8px solid transparent; bottom: -16px; right: 12px; } */
    .openConfMenu.slideUp {  display: flex; height: calc(100% - 132px); visibility: visible; opacity: 1;  }
    
    .mobRight{ right: 0; position: absolute !important; top: 50%;
        transform: translateY(-50%); padding:0 16px; }

    .patientCallContainer {overflow-x: hidden; overflow-y: scroll; scroll-behavior: smooth;}
    .unmutePopup {min-width:320px;}
    .unmutePopup:after {left: calc(50% - 120px);}
    /** added on 11th April 2020 ***/
    .presenterLeftInner { height: 100%; justify-content: space-between; }

    /* added on april 2020 */
    .screenContainer { flex-direction: row; }
    .confScreenContainer {flex-direction: row; }
    .prsntLeftBlock, .prsntRightBlock{ width: 50%; height: calc(100vh - 64px);}
    .inlineMeida210 { width: 50%; }
    .overlayCenterButton { margin-top: 0; }
    .confModeTxt { bottom: 72px}
    .confModeTxt span { font-size: 12px; color: rgba(255,255,255,0.5); }
    .screensTileBlock { padding: 0 16px; }
    

}

