:root {
	--whiteBg: #fff;
}

.header {z-index: 999; display: flex; flex: 1; position: fixed; height: 64px; left: 0; top: 0; right: 0; box-sizing: border-box; background-color: var(--whiteBg); border-bottom: 1px solid rgba(0,0,0,0.12); align-items: center; padding: 0 24px;}
.leftLogoBlock {flex: 1 1 auto; padding: 0; box-sizing: border-box; justify-content: center; align-items: center; }
.logoBlock { width: 128px; flex: 0 0 auto; display:flex;}
.logoBlock img {width: auto; height: 48px; }
.logoBlock a {display: flex; align-items: center;}
.displayOrNot {display: none !important;}
.brodcastFlash { display: flex; flex: 0 0 auto; align-items: center; }

.headerAvatarBlock { flex: 0 0 auto; display: flex; align-items: center; position: relative; z-index: 999;}
.headerAvatarBlock:hover{cursor: pointer;}
.userName {flex: 1 1 auto; padding: 0 16px; display: flex; text-align: right;}
.userName span {width: inherit; font-size: 14px; color: rgba(0,0,0,0.87); font-weight: 600; text-transform: capitalize;;}
.userName span:after { content: "\25bc"; margin-left: 4px; font-size: 10px; position: relative; top: -1px; }
.userAvatar {flex: 0 0 auto; width: 40px; height: 40px; border-radius: 50%; background-color: #F1EDE3;}
.userAvatar img {width: 100%; height: 100%; border-radius: 50%; object-fit: cover;}


/***** HEADER DROP DOWN MENU *****/
.headerAvaDrpDwn { display: none; position: absolute; background-color: #fff; min-width: 140px; min-height: 136px; box-shadow: 1px -4px 48px 6px rgba(0,0,0,0.24); z-index: 999; top: 44px; border-radius: 4px; transition: 0.5s; margin-top: 12px; right: 16px; }
.headerAvaDrpDwn:after { content: ""; position: absolute; z-index: -1; width: 0; height: 0; background-color: var(--whitePrimary); top: -19px; right: 0; background-color: transparent; transform: rotate(0); border-top: 24px solid transparent; border-right: 24px solid #fff; border-bottom: 24px solid #fff; border-left: 24px solid transparent; box-shadow: -1px 8px 22px rgba(0,0,0,0); }
.headerAvaDrpDwn ul {list-style-type: none;margin: 0; padding: 8px 0; display: flex; flex-direction: column;flex: 1;}
.headerAvaDrpDwn ul li {text-decoration: none; display: flex; flex: 1;}
.headerAvaDrpDwn ul li a { color: rgba(0,0,0,0.87); padding: 12px 16px; text-decoration: none; display: flex; font-size: 14px; flex: 1; align-items: center; }
.headerAvaDrpDwn ul li a i { margin-right: 8px; }
.headerAvaDrpDwn ul li:hover a,
.headerAvaDrpDwn ul li:active a{background-color: #f5f5f5; color: #3397ED;}
.show {display: flex;}


@media (min-width: 768px) and (max-width: 1024px) {
	.displayOrNot {display: flex !important;}
	.header {height: 56px; }
}

@media (min-width: 320px) and (max-width: 767px) {
	.displayOrNot {display: flex !important;}
	.logoBlock {width:96px !important;}
	.logoBlock img {width: 100%; height: 100%;}
	.header {height: 56px; }
	/** ADDED APRIL 2020 **/
	.userName span { width: 104px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }
}
