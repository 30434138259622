html,body { height: 100%; }
body { margin: 0; padding: 0; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",   sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; background-color: var(--basePrimary); }
article {background-color: var(--whitePrimary);}
code { font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }
#root { height: 100%; }
:root{
	--primaryColor: #008abc;
  --primaryLight: #b3dceb;
  --primaryLightDark: #eaf3f7;
	--primaryDark: #0077ad;
	--whitePrimary: #fff;
	--whitePrimaryInactive: rgba(255,255,255,0.7);
	--blackTxtPrimary: rgba(0,0,0,0.87);
	--blackTxtPrimaryInactive: rgba(0,0,0,0.54);
	--blackTxtPrimaryDisable: rgba(0,0,0,0.38);
	--brdrDark: 1px solid rgba(0,0,0,0.12);
	--subHeading: 20px;
  --lightGray: #f5f5f5;
  --searchBg: #dfdfdf;
  --chipBg: #eee;
	--circleM: 40px;
  --blueBg: #00A2FF;
  --yellowBg: #F8BA00;
  --pinkBg: #EF5FA7;
  --orangeBg: #FF9300;
  --greenDBg: #4F8F00;
  --violetBg: #7A81FF;
	--purpleBg: #9437FF;
  --endBg: #fd594f;
  --grayDefault: #a6a6a6;
  --primaryHover: linear-gradient(90deg, #00aafa, #2F8CDA );

  
	--brdrDark: 1px solid rgba(0,0,0,0.12);
	--secondaryBg: #ff9200;
	--secondaryDarkBg: #c67100;
	--primaryHover: linear-gradient(90deg, #00aafa, #2F8CDA ); --lightSecondaryGray: #efefef;

  --purpleBg: #b265bb;
  --greenBg: #1bbf46;
  --safronBg: #ffb04d;
  --tealGreenBg: #00bcd4;
  --dangerBg: #d65345;
  --inactiveBg: #b2b2b2;
  --successColor: #54a162;
  --failureColor: #ec655f;
  --deepBlue: #0433FF;
  --borwnBg: #941100;

  /* conference bg */
  --confBg: #191919;

  /** added April 2020 **/
    --basePrimary: #F7F8Fa;
    --baseDark: #E6E6E6;
    --brdrColor: rgba(0,0,0,0.12);
    --blackPrimary: rgba(0,0,0,0.87);
    --blackLight: rgba(0,0,0,0.54);
    --blackDisable: rgba(0,0,0,0.38);

    /* GRADE COLORS */
    --gradeA: #96D5A5;
    --gradeB: #7A81FF;
    --gradeC: #F7C787;
    --gradeD: #FF7E79;

    /* STATUS COLORS */
    --warningStatus:#ffe5c7 ;
    --successStatus: #b5ebcc;
    --errorStatus: #ffe3e0;
    --infoStatus: #c2edff;
}

/* start CSS for background colors */
.blueBg { background-color: var(--blueBg) !important; }
.yellowBg { background-color: var(--yellowBg) !important; }
.pinkBg { background-color: var(--pinkBg) !important; }
.orangeBg { background-color: var(--orangeBg) !important; }
.greenBg { background-color: var(--greenBg) !important; }
.violetBg { background-color: var(--violetBg) !important; }
.purpleBg { background-color: var(--purpleBg) !important; }
.endBg { background-color: var(--endBg);}
.grayBg {background-color: var(--grayDefault);}
.primaryLightBg {background-color: #4dadd0 !important;}
/* end CSS for background colors */

h1,h2,h3,h4,h5,h6 { margin: 0; padding: 0;  }
h2 { font-size: 20px; font-weight: 450; color: var(--blackTxtPrimary); }
a, p { font-size: 14px; }
button { outline: none; cursor: pointer;}
.paraText { margin: 0 0 12px ; color: var(--blackLight);}

.secondaryTxtColor {color:var(--secondaryBg) !important; font-weight: 550!important;}
/* borders */
.online{border: 3px solid green;} 
.offline{border: 3px solid gray;}

/* UTILITY CLASSES */
.flexRow {
  display: flex;
  flex: none;
  width: 100%;
}
.flexCol {
  display: flex;
  flex-direction: column;
  flex: none;
  width: 100%;
}
.resRowCol {
  display: flex;
  flex: none;
  flex-direction: row;
  width: 100%;
}
.flexGrow {
  display: flex;
  flex-grow: 1;
}
.pdngRSM{padding-right: 8px;}
.respdngTXSM{padding-top: 0px;}
.mrgnBtm{margin-bottom: 8px;}
.lightTxt{color: rgba(0,0,0,0.54); font-size: 14px;}
.inlineBlock{
  position: relative;
}
.inlineBlock .inlineBlock3 {
  width: calc(100% / 3);
}
/****** 404 ERROR PAGE ***********/
.layout404 { display: flex; position: absolute; /* z-index: 1000;*/ top:0; left: 0; width: 100%; height: 100%;}
.PageAbsoluteCenter{position: absolute; z-index: 99999; top: 0; right: 0; bottom: 0; left: 0;}
.pageCenterBlock { padding: 0; list-style: none; display: flex; align-items: center; justify-content: center; position: relative; top: 0; flex-direction: column; flex: 1;}
.pageCenterIcon { position: relative; display: flex; align-items: center; justify-content: center; width: 128px; height: 128px;}
.pageCenterIcon img{width: 100%; height: auto;}
.opacity54 {opacity: 0.54;}

.browserTxtBlock {position: relative; margin-top:32px;}
.browserWidth640px {width:640px;}
.browserBlock { position: relative; display: flex; align-items: center; justify-content: center; margin-top: 24px; flex-wrap:wrap;}
.browserIconInfo {display:flex; flex-direction: column; align-items: center; justify-content: center;}
.browserIconInfo:hover {background-color: #fff; cursor: pointer; box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px; border-radius: 2px; }
    
.browserIcon {width: 80px; height: 80px; align-items: center; justify-content: center; padding: 0 32px; margin: 24px 0;}
.browserIcon img {width:100%; height: 100%; opacity: unset;}
.browserName {display: flex; flex-direction: column; margin-bottom: 24px; align-items: center;}
.browserName p {font-size: 14px; font-weight: 550; color:#008abc; margin:0; padding: 0;}
.browserName span {font-size: 14px; font-weight:500; color: rgba(0,0,0,0.54); line-height: 20px; }
.footerMsg{padding: 24px 16px; position: relative; margin-bottom:40px; display:flex; flex:0 0 auto;}
.footerMsg p{font-size: 14px; color: rgba(0,0,0,0.54);}
.footerMsg a{color: #008abc;}

.pageCenterText {flex: 0 0 auto; display: flex; flex-direction: column; padding:0 16px;}
.pageCenterText h2{font-size: 56px; text-align: center; font-weight: 500; color: var(--blackTxtPrimary); margin-top: 8px;}
.pageCenterText h3{font-size: 40px; text-align: center; font-weight: 450; color: var(--blackTxtPrimary); margin-top: 8px;}
.pageCenterText h4{font-size: 24px; text-align: center; font-weight: 450; color: var(--blackTxtPrimary); margin-top: 8px;}
.pageCenterText .heading32{font-size: 32px; text-align: center; font-weight: 450; color: var(--blackTxtPrimary); margin-top: 8px;}
.pageCenterText p {color: var(--blackTxtPrimaryInactive); text-align:center; margin:8px 0 0;}
.pageCenterButton {border-radius: 99px; position: relative; display: inline-flex; width: 100%; margin-top: 60px; align-items: center; justify-content: center; text-transform: uppercase; font-size: 14px; font-weight: bold; outline: none; border: none; }
.pageCenterButton a {border-radius: 99px; text-decoration: none;}
.pageCenterActionButtons {border-radius: 99px; position: relative; display: inline-flex; width: 100%; margin-top: 32px; align-items: center; justify-content: center; text-transform: uppercase; font-size: 14px; font-weight: bold; outline: none; border: none; }
.pageCenterActionButtons a {border-radius: 99px; text-decoration: none;}
.vertBtns {flex-direction: column;}
.pendingMsgBlock {background-color:#fcf8e3; padding: 8px; border:1px solid #fbeccd;}
.blackOverlay { position: absolute; left: 0; top: 0; width: 100%; height: 100%; text-align: center; z-index: 1001; display: flex; background: rgba(0,0,0,0.87); }
.whiteOverlay { position: absolute; left: 0; top: 0; width: 100%; height: 100%; text-align: center; z-index: 1001; display: flex; background: rgba(255,255,255,0.87); }
.pointsTxt {padding: 24px;}
.pointsTxt ul {list-style-type:square; margin: 8px 0 0 40px;}
.pointsTxt ul li {position: relative;}
.pointsTxt ul li p{font-size: 14px; color: rgba(0,0,0,0.54); text-align: left;}
.videoElm { width: 100%; height: 100%; object-fit: cover; }
.videoElmMix {width: 100%; height: 100%; object-fit: contain;}
.mixVideoLayout1 { position: absolute; display: flex; align-items: center; justify-content: center; }
.mixVideoLayout { position: absolute; width: calc(calc(100vh - 80px) * 16/9); height: calc(100% - 80px); left: 50%; transform: translateX(-50%) }
.videoBox { width: 320px; height: 240px; }
.videoBoxTest { width: 500px; height: 300px; position: absolute; background-color: red; }
.muteBox { position: absolute; height: 24px; z-index: 1; bottom: 5px; right: 5px; display: none; cursor: pointer; }
.toSp { position: absolute; height: 24px; z-index: 3; bottom: 5px; left: 5px; cursor: pointer; }
.toSpStop { position: absolute; height: 24px; z-index: 3; bottom: 15px; left: 15px; display:none; cursor: pointer; }
.toCast { bottom: 5px; position: absolute; right: 5px; z-index: 4; height: 24px; background-color: var(--whitePrimary); cursor: pointer; }
.pipBox { position: absolute; height: 24px; z-index: 1; top: 5px; right: 5px; cursor: pointer; }
.statBox { position: absolute; height: 19px; z-index: 1; top: 5px; right: 40px; cursor: pointer; }
.muteStatus { position: absolute; top: 0; color: #fff; background-color: rgba(255,255,255,0.1); font-size: 11px; padding: 5px; height: 15px; font-weight: bold; display: none; }
.nameBox { position: absolute; color: #fff; font-weight: bold; bottom: 4%; left: 0; right: 0; text-align: center; background-color: transparent; font-size: 1vw; text-shadow: 2px 2px 5px #000; }
.mcuLocal { display: none; position: fixed; width: 350px; right: 0; bottom: 80px; background-color: #000; }
.mcuLocalView {  display: block;  position: fixed;  width: 350px;  right: 0;  bottom: 80px;  background-color: #000; }
.mcuRemote { width: 100%; height: calc(100vh - 80px); }
.screenShare { position: fixed;  display: none; left: 0; top: 0;  width: calc(100vw - 420px); height: calc(100vh - 80px); overflow: hidden; }
.screenShare video {width: 100%; height: 100%; object-fit: contain !important; }
#br_plyr { height: calc(100vh - 320px) !important;     width: calc(calc(100vh - 320px) * 1.77777778) !important; }

.invertBlack   { -moz-filter: brightness(0) invert(0); -webkit-filter: brightness(0) invert(0); filter: brightness(0) invert(0); }
.invertWhite  { -moz-filter: brightness(0) invert(1); -webkit-filter: brightness(0) invert(1); filter: brightness(0) invert(1); }
.invertGray   { -moz-filter: grayscale(100%); -webkit-filter: grayscale(100%); filter: grayscale(100%); }
.invertWhiteInactive {-moz-filter: brightness(0) invert(0.54); -webkit-filter: brightness(0) invert(0.54); filter: brightness(0) invert(0.54);}

.infoText { font-size: 0.875rem; color: var(--blackLight); margin: 12px 0; }
/*********** TABLET RESPONSIVE **********/
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .mixVideoLayout { width: auto; height: 100%; }
  /* .browserBlock {flex-direction: column;} */
  .browserWidth640px {width:100%;}
}
@media (min-width: 768px) and (max-width: 1024px){
  .mixVideoLayout { width: 100%; height: auto; }
  .confModeTxt { display: none !important; }
  /* .browserBlock {flex-direction: column;} */
  .browserWidth640px {width:100%;}
  .videoElm,.videoElmMix { width: 100%; height: auto; object-fit: initial; }
}

/*********** TABLET MOBILE **********/
@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
  .mixVideoLayout { width: 100%; height: auto; }
  .confModeTxt { display: none !important; }
  /* .browserBlock { flex-direction: column; } */
  .browserWidth640px {width:100%;}
  /***** Suggesatable For Share Tools *****/
  /* .videoElm {width: 100%; height: auto; object-fit: fill;} */
  
}
@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  /***** Suggesatable For Share Tools *****/
  /* .videoElm {width: auto; height: 100%; object-fit: fill;} */
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  /* .browserBlock {flex-direction: column;} */
  /* .videoElm, .videoElmMix { width: 100%; height: auto; object-fit: initial; } */
  .browserWidth640px {width:100%;}
  .resRowCol {flex-direction: column;}
  .inlineBlock .inlineBlock3 {
    width: calc(100% / 1);
  }
  .respdngTXSM{padding-top: 4px;}

  
}