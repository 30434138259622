* {box-sizing:  border-box;}
:root{
    --conference: #042531;
    --exitRed: #FF2600;
    --whiteTxt: #FFF;
    --primary: #008ABC;
    --videoBg: #1A1A1A;
}
.p2pFloatCntnr { display: flex; flex: none; width: 100%; height: 100%; position: fixed; bottom: 4px; right: 4px; left: 0; top: 0; z-index: 1000; background-color: var(--conference); flex-direction: column; overflow: hidden; }
.p2pFloatHdr { display: flex; flex: none; width: 100%; padding: 8px 0; align-items: center; justify-content: center; position: relative; }
.p2pFloatBdy { display: flex; flex: 1; min-height: 0; flex-direction: column; align-items: center; justify-content: center; }
.p2pFloatFtr { display: flex; flex: none; width: 100%; height: 72px; align-items: center; justify-content: center; }

.btnExit { min-width: 32px; height: 32px; border: none; box-shadow: none; display: inline-flex; flex: none; padding: 7px 16px; align-items: center; justify-content: center; background-color: var(--exitRed); border-radius: 4px; }
.btnExitTxt { font-weight: 600; font-size: 0.875rem; text-transform: capitalize; color: var(--whiteTxt); line-height: 22px; letter-spacing: 0.875px; }
.abslutTools { display: none; }

.p2pBtnActnGroup { display: inline-flex; margin: 0 auto; flex: none; align-items: center; justify-content: center; }
.p2pBtn { width: 56px; height: 56px; border-radius: 50%; display: flex; flex: none; align-items: center; justify-content: center; border: 1px solid rgba(255,255,255,0.16); outline: none; cursor: pointer; margin: 0 6px; }
.p2pBtn.btn_primary { background-color: var(--primary); }
.p2pBtn img { width: 24px; height: 24px;}

.p2pVideoCntnr {display: flex; width: 100%; flex-direction: column; align-items: center; justify-content: center; padding: 16px; height: 100%; }
.p2pMediaRemote { display: flex; width: 80%; height: 100%; flex-direction: column; flex: none; position: relative; align-items: center; justify-content: center; }
.selfVideo { position: relative; }
.p2pVideo16x9 { width: 100%; height: 0; padding-bottom: 56.25%; position: relative; overflow: hidden; background-color: var(--videoBg); }
.p2pVideoBox { width: 100%; height: 100%; position: absolute; top: 0; right: 0; bottom: 0; left: 0; }
.p2pVideoBox video, .p2pVideoBox img { width: 100%; height: 100%; object-fit: cover; position: relative; }
.p2pFullScreen { display: none; }


@media (min-width: 300px) and (max-width: 767px) and (orientation: landscape)  {
    .selfVideo { width: 160px; height: 90px; position: absolute; overflow: hidden; padding: 0; bottom: 0; right: 0; }
    .p2pMediaRemote { align-items: center; height: auto; width: 50%; }
}

@media (min-width: 768px) and (max-width: 1112px) and (orientation: landscape)  {
    .selfVideo { width: 160px; height: 90px; position: absolute; overflow: hidden; padding: 0; bottom: 0; right: 0; }
    .p2pMediaRemote { align-items: center; height: auto; width: 50%; }
}

@media (min-width: 1113px) and (max-width: 1366px){
    .p2pFloatCntnr { width: 448px; height: auto; right: 0; left: auto; top: auto; border-radius: 4px; }
    .p2pVideoBox #self { position: absolute; top: auto; right: 0; bottom: 0; left: auto; z-index: 1; width: 160px; height: 90px; }
    .p2pFullScreen { display: flex; width: 100%; height: 100%; transition: all 0.3s ease; }
    .p2pFullScreen .p2pVideoCntnr { width: 70%; }
    .abslutTools { display: flex; flex: none; min-width: 40px; height: 40px; position: absolute; right: 0; align-items: center; justify-content: center; cursor: pointer; }
    .abslutTools .abslutIcom { width: 20px; height: 20px;}
    .p2pMediaRemote { align-items: center; height: auto; }
    .selfVideo { width: 160px; height: 90px; position: absolute; overflow: hidden; padding: 0; bottom: 0; right: 0; }
}

@media (min-width: 1367px){
    .p2pFloatCntnr { width: 448px; height: auto; right: 0; left: auto; top: auto; border-radius: 4px; }
    .p2pVideoBox #self { position: absolute; top: auto; right: 0; bottom: 0; left: auto; z-index: 1; width: 160px; height: 90px; }
    .p2pFullScreen { display: flex; width: 100%; height: 100%; transition: all 0.3s ease; }
    .p2pFullScreen .p2pVideoCntnr { width: 70%; }
    .abslutTools { display: flex; flex: none; min-width: 40px; height: 40px; position: absolute; right: 0; align-items: center; justify-content: center; cursor: pointer; }
    .abslutTools .abslutIcom { width: 20px; height: 20px;}
    .p2pMediaRemote { align-items: center; height: auto; }
    .selfVideo { width: 160px; height: 90px; position: absolute; overflow: hidden; padding: 0; bottom: 0; right: 0; }
}
